
import * as React from "react"
import Layout from "../components/layout"


const ConstructionPage = () => {
    return (
        <Layout pageTitle="Construction">

<p>In 40-plus years of building and as a licensed General Contractor, we have been involved in projects ranging in size from large wineries, hotels and resorts, subdivision and commercial development, to substantial and modest home remodels.</p>



<p>We bring tremendous skill, pride and organization to the process of building successful, well planned, and well managed and executed construction projects.  We approach all projects, regardless of size, with the same  professional attention to detail and insistence on client satisfaction.</p>



<p>When you hire Thornley Associates, you are hiring a “hands on” General Contractor whose range of talent and depth of experience pays dividends throughout the entire construction process, and for many years after completion of a project. There is simply no substitution for it - from the design phase to the final punch list.</p>
</Layout>
    )}

    export default ConstructionPage